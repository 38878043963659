/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * SCSS Variables.
 *
 * Please use variables from this sheet to ensure consistency across the UI.
 * Don't add to this sheet unless you're pretty sure the value will be reused in many places.
 * For example, don't add rules to this sheet that affect block visuals. It's purely for UI.
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block & Editor UI.
 */
/**
 * Block paddings.
 */
/**
 * React Native specific.
 * These variables do not appear to be used anywhere else.
 */
/**
*  Converts a hex value into the rgb equivalent.
*
* @param {string} hex - the hexadecimal value to convert
* @return {string} comma separated rgb values
*/
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Focus styles.
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
:root {
  --wp-admin-theme-color: #007cba;
  --wp-admin-theme-color--rgb: 0, 124, 186;
  --wp-admin-theme-color-darker-10: #006ba1;
  --wp-admin-theme-color-darker-10--rgb: 0, 107, 161;
  --wp-admin-theme-color-darker-20: #005a87;
  --wp-admin-theme-color-darker-20--rgb: 0, 90, 135;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  :root {
    --wp-admin-border-width-focus: 1.5px;
  }
}

.components-panel__header.interface-complementary-area-header__small {
  background: #fff;
  padding-right: 4px;
}
.components-panel__header.interface-complementary-area-header__small .interface-complementary-area-header__small-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
@media (min-width: 782px) {
  .components-panel__header.interface-complementary-area-header__small {
    display: none;
  }
}

.interface-complementary-area-header {
  background: #fff;
  padding-right: 4px;
}
.interface-complementary-area-header .components-button.has-icon {
  display: none;
  margin-left: auto;
}
.interface-complementary-area-header .components-button.has-icon ~ .components-button {
  margin-left: 0;
}
@media (min-width: 782px) {
  .interface-complementary-area-header .components-button.has-icon {
    display: flex;
  }
}

@media (min-width: 782px) {
  .components-panel__header + .interface-complementary-area-header {
    margin-top: 0;
  }
}

.interface-complementary-area {
  background: #fff;
  color: #1e1e1e;
}
@media (min-width: 600px) {
  .interface-complementary-area {
    -webkit-overflow-scrolling: touch;
  }
}
@media (min-width: 782px) {
  .interface-complementary-area {
    width: 280px;
  }
}
.interface-complementary-area .components-panel {
  border: none;
  position: relative;
  z-index: 0;
}
.interface-complementary-area .components-panel__header {
  position: sticky;
  top: 0;
  z-index: 1;
}
.interface-complementary-area .components-panel__header.edit-post-sidebar__panel-tabs {
  top: 48px;
}
@media (min-width: 782px) {
  .interface-complementary-area .components-panel__header.edit-post-sidebar__panel-tabs {
    top: 0;
  }
}
.interface-complementary-area p {
  margin-top: 0;
}
.interface-complementary-area h2 {
  font-size: 13px;
  color: #1e1e1e;
  margin-bottom: 1.5em;
}
.interface-complementary-area h3 {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 1.5em;
}
.interface-complementary-area hr {
  border-top: none;
  border-bottom: 1px solid #f0f0f0;
  margin: 1.5em 0;
}
.interface-complementary-area div.components-toolbar-group,
.interface-complementary-area div.components-toolbar {
  box-shadow: none;
  margin-bottom: 1.5em;
}
.interface-complementary-area div.components-toolbar-group:last-child,
.interface-complementary-area div.components-toolbar:last-child {
  margin-bottom: 0;
}
.interface-complementary-area .block-editor-skip-to-selected-block:focus {
  top: auto;
  right: 10px;
  bottom: 10px;
  left: auto;
}

@media (min-width: 782px) {
  body.js.is-fullscreen-mode {
    margin-top: -32px;
    height: calc(100% + 32px);
  }
  body.js.is-fullscreen-mode #adminmenumain,
body.js.is-fullscreen-mode #wpadminbar {
    display: none;
  }
  body.js.is-fullscreen-mode #wpcontent,
body.js.is-fullscreen-mode #wpfooter {
    margin-left: 0;
  }
}

html.interface-interface-skeleton__html-container {
  position: fixed;
  width: 100%;
}
@media (min-width: 782px) {
  html.interface-interface-skeleton__html-container {
    position: initial;
    width: initial;
  }
}

.interface-interface-skeleton {
  display: flex;
  flex-direction: row;
  height: auto;
  max-height: 100%;
  position: fixed;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (min-width: 783px) {
  .interface-interface-skeleton {
    top: 32px;
  }
  .is-fullscreen-mode .interface-interface-skeleton {
    top: 0;
  }
}

.interface-interface-skeleton__editor {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  overflow: hidden;
}

.interface-interface-skeleton {
  /* Set left position when auto-fold is not on the body element. */
  left: 0;
}
@media (min-width: 783px) {
  .interface-interface-skeleton {
    left: 160px;
  }
}

.auto-fold .interface-interface-skeleton {
  /* Auto fold is when on smaller breakpoints, nav menu auto collapses. */
}
@media (min-width: 783px) {
  .auto-fold .interface-interface-skeleton {
    left: 36px;
  }
}
@media (min-width: 961px) {
  .auto-fold .interface-interface-skeleton {
    left: 160px;
  }
}

/* Sidebar manually collapsed. */
.folded .interface-interface-skeleton {
  left: 0;
}
@media (min-width: 783px) {
  .folded .interface-interface-skeleton {
    left: 36px;
  }
}

body.is-fullscreen-mode .interface-interface-skeleton {
  left: 0 !important;
}

.interface-interface-skeleton__body {
  flex-grow: 1;
  display: flex;
  overflow: auto;
  overscroll-behavior-y: none;
}
@media (min-width: 782px) {
  .has-footer .interface-interface-skeleton__body {
    padding-bottom: 25px;
  }
}

.interface-interface-skeleton__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 91;
}

.interface-interface-skeleton__secondary-sidebar,
.interface-interface-skeleton__sidebar {
  display: block;
  flex-shrink: 0;
  position: absolute;
  z-index: 100000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  color: #1e1e1e;
}
@media (min-width: 782px) {
  .interface-interface-skeleton__secondary-sidebar,
.interface-interface-skeleton__sidebar {
    position: relative !important;
    z-index: 90;
    width: auto;
  }
}

.interface-interface-skeleton__sidebar {
  overflow: auto;
}
@media (min-width: 782px) {
  .interface-interface-skeleton__sidebar {
    border-left: 1px solid #e0e0e0;
  }
}

@media (min-width: 782px) {
  .interface-interface-skeleton__secondary-sidebar {
    border-right: 1px solid #e0e0e0;
  }
}

.interface-interface-skeleton__header {
  flex-shrink: 0;
  height: auto;
  border-bottom: 1px solid #e0e0e0;
  z-index: 30;
  color: #1e1e1e;
}

.interface-interface-skeleton__footer {
  height: auto;
  flex-shrink: 0;
  border-top: 1px solid #e0e0e0;
  color: #1e1e1e;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 90;
  display: none;
}
@media (min-width: 782px) {
  .interface-interface-skeleton__footer {
    display: flex;
  }
}
.interface-interface-skeleton__footer .block-editor-block-breadcrumb {
  z-index: 30;
  display: flex;
  background: #fff;
  height: 24px;
  align-items: center;
  font-size: 13px;
  padding: 0 18px;
}

.interface-interface-skeleton__actions {
  z-index: 100000;
  position: fixed !important;
  top: -9999em;
  bottom: auto;
  left: auto;
  right: 0;
  width: 280px;
  color: #1e1e1e;
}
.interface-interface-skeleton__actions:focus {
  top: auto;
  bottom: 0;
}

.interface-more-menu-dropdown {
  margin-left: -4px;
}
.interface-more-menu-dropdown .components-button {
  width: auto;
  padding: 0 2px;
}
@media (min-width: 600px) {
  .interface-more-menu-dropdown {
    margin-left: 0;
  }
  .interface-more-menu-dropdown .components-button {
    padding: 0 4px;
  }
}

.interface-more-menu-dropdown__content .components-popover__content {
  min-width: 280px;
}
@media (min-width: 480px) {
  .interface-more-menu-dropdown__content .components-popover__content {
    width: auto;
    max-width: 480px;
  }
}
.interface-more-menu-dropdown__content .components-popover__content .components-dropdown-menu__menu {
  padding: 0;
}

.components-popover.interface-more-menu-dropdown__content {
  z-index: 99998;
}

.interface-pinned-items {
  display: flex;
}
.interface-pinned-items .components-button:not(:first-child) {
  display: none;
}
@media (min-width: 600px) {
  .interface-pinned-items .components-button:not(:first-child) {
    display: flex;
  }
}
.interface-pinned-items .components-button {
  margin-left: 4px;
}
.interface-pinned-items .components-button svg {
  max-width: 24px;
  max-height: 24px;
}

@media (min-width: 600px) {
  .interface-preferences-modal {
    width: calc(100% - 32px);
    height: calc(100% - 120px);
  }
}
@media (min-width: 782px) {
  .interface-preferences-modal {
    width: 750px;
  }
}
@media (min-width: 960px) {
  .interface-preferences-modal {
    height: 70%;
  }
}
@media (max-width: 781px) {
  .interface-preferences-modal .components-modal__content {
    padding: 0;
  }
  .interface-preferences-modal .components-modal__content::before {
    content: none;
  }
}

.interface-preferences__tabs .components-tab-panel__tabs {
  position: absolute;
  top: 84px;
  left: 16px;
  width: 160px;
}
.interface-preferences__tabs .components-tab-panel__tabs .components-tab-panel__tabs-item {
  border-radius: 2px;
  font-weight: 400;
}
.interface-preferences__tabs .components-tab-panel__tabs .components-tab-panel__tabs-item.is-active {
  background: #f0f0f0;
  box-shadow: none;
  font-weight: 500;
}
.interface-preferences__tabs .components-tab-panel__tabs .components-tab-panel__tabs-item:focus:not(:disabled) {
  box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color);
}
.interface-preferences__tabs .components-tab-panel__tab-content {
  padding-left: 24px;
  margin-left: 160px;
}

@media (max-width: 781px) {
  .interface-preferences__provider {
    height: 100%;
  }
}
.interface-preferences-modal__section {
  margin: 0 0 2.5rem 0;
}
.interface-preferences-modal__section:last-child {
  margin: 0;
}

.interface-preferences-modal__section-title {
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 0;
}

.interface-preferences-modal__section-description {
  margin: -8px 0 8px 0;
  font-size: 12px;
  font-style: normal;
  color: #757575;
}

.interface-preferences-modal__option .components-base-control .components-base-control__field {
  align-items: center;
  display: flex;
  margin-bottom: 0;
}
.interface-preferences-modal__option .components-base-control .components-base-control__field > label {
  flex-grow: 1;
  padding: 0.6rem 0 0.6rem 10px;
}
.interface-preferences-modal__option .components-base-control__help {
  margin: -8px 0 8px 58px;
  font-size: 12px;
  font-style: normal;
  color: #757575;
}

.edit-post-header {
  height: 60px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100vw;
}
@media (min-width: 280px) {
  .edit-post-header {
    flex-wrap: nowrap;
  }
}
.edit-post-header > .edit-post-header__settings {
  order: 1;
}
@supports (position: sticky) {
  .edit-post-header > .edit-post-header__settings {
    order: initial;
  }
}

.edit-post-header__toolbar {
  display: flex;
  flex-grow: 1;
}
.edit-post-header__toolbar .table-of-contents {
  display: none;
}
@media (min-width: 600px) {
  .edit-post-header__toolbar .table-of-contents {
    display: block;
  }
}

.edit-post-header__settings {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 4px;
  /**
   * Buttons in the Toolbar
  	 */
}
@media (min-width: 600px) {
  .edit-post-header__settings {
    padding-right: 16px;
  }
}
.edit-post-header__settings .editor-post-saved-state,
.edit-post-header__settings .components-button.components-button {
  margin-right: 4px;
}
@media (min-width: 600px) {
  .edit-post-header__settings .editor-post-saved-state,
.edit-post-header__settings .components-button.components-button {
    margin-right: 12px;
  }
}
.edit-post-header__settings .editor-post-saved-state,
.edit-post-header__settings .components-button.is-tertiary {
  padding: 0 6px;
}
.edit-post-header__settings .interface-more-menu-dropdown .components-button,
.edit-post-header__settings .interface-pinned-items .components-button {
  margin-right: 0;
}

.edit-post-header-preview__grouping-external {
  display: flex;
  position: relative;
  padding-bottom: 0;
}

.edit-post-header-preview__button-external {
  padding-left: 8px;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.edit-post-header-preview__button-external svg {
  margin-left: auto;
}

.edit-post-post-preview-dropdown .components-popover__content > div {
  padding-bottom: 0;
}

.show-icon-labels.interface-pinned-items .components-button.has-icon,
.show-icon-labels .edit-post-header .components-button.has-icon,
.edit-post-header__dropdown .components-button.has-icon {
  width: auto;
}
.show-icon-labels.interface-pinned-items .components-button.has-icon svg,
.show-icon-labels .edit-post-header .components-button.has-icon svg,
.edit-post-header__dropdown .components-button.has-icon svg {
  display: none;
}
.show-icon-labels.interface-pinned-items .components-button.has-icon::after,
.show-icon-labels .edit-post-header .components-button.has-icon::after,
.edit-post-header__dropdown .components-button.has-icon::after {
  content: attr(aria-label);
}
.show-icon-labels.interface-pinned-items .components-button.has-icon[aria-disabled=true],
.show-icon-labels .edit-post-header .components-button.has-icon[aria-disabled=true],
.edit-post-header__dropdown .components-button.has-icon[aria-disabled=true] {
  background-color: transparent;
}
.show-icon-labels.interface-pinned-items .is-tertiary:active,
.show-icon-labels .edit-post-header .is-tertiary:active,
.edit-post-header__dropdown .is-tertiary:active {
  box-shadow: 0 0 0 1.5px var(--wp-admin-theme-color);
  background-color: transparent;
}
.show-icon-labels.interface-pinned-items .edit-post-fullscreen-mode-close.has-icon svg,
.show-icon-labels.interface-pinned-items .components-button.has-icon.button-toggle svg,
.show-icon-labels .edit-post-header .edit-post-fullscreen-mode-close.has-icon svg,
.show-icon-labels .edit-post-header .components-button.has-icon.button-toggle svg,
.edit-post-header__dropdown .edit-post-fullscreen-mode-close.has-icon svg,
.edit-post-header__dropdown .components-button.has-icon.button-toggle svg {
  display: block;
}
.show-icon-labels.interface-pinned-items .edit-post-fullscreen-mode-close.has-icon::after,
.show-icon-labels.interface-pinned-items .components-button.has-icon.button-toggle::after,
.show-icon-labels .edit-post-header .edit-post-fullscreen-mode-close.has-icon::after,
.show-icon-labels .edit-post-header .components-button.has-icon.button-toggle::after,
.edit-post-header__dropdown .edit-post-fullscreen-mode-close.has-icon::after,
.edit-post-header__dropdown .components-button.has-icon.button-toggle::after {
  content: none;
}
.show-icon-labels.interface-pinned-items .edit-post-fullscreen-mode-close.has-icon,
.show-icon-labels .edit-post-header .edit-post-fullscreen-mode-close.has-icon,
.edit-post-header__dropdown .edit-post-fullscreen-mode-close.has-icon {
  width: 60px;
}
.show-icon-labels.interface-pinned-items .components-menu-items-choice .components-menu-items__item-icon.components-menu-items__item-icon,
.show-icon-labels .edit-post-header .components-menu-items-choice .components-menu-items__item-icon.components-menu-items__item-icon,
.edit-post-header__dropdown .components-menu-items-choice .components-menu-items__item-icon.components-menu-items__item-icon {
  display: block;
}
.show-icon-labels.interface-pinned-items .edit-post-header-toolbar__inserter-toggle.edit-post-header-toolbar__inserter-toggle,
.show-icon-labels.interface-pinned-items .interface-pinned-items .components-button,
.show-icon-labels .edit-post-header .edit-post-header-toolbar__inserter-toggle.edit-post-header-toolbar__inserter-toggle,
.show-icon-labels .edit-post-header .interface-pinned-items .components-button,
.edit-post-header__dropdown .edit-post-header-toolbar__inserter-toggle.edit-post-header-toolbar__inserter-toggle,
.edit-post-header__dropdown .interface-pinned-items .components-button {
  padding-left: 8px;
  padding-right: 8px;
}
@media (min-width: 600px) {
  .show-icon-labels.interface-pinned-items .edit-post-header-toolbar__inserter-toggle.edit-post-header-toolbar__inserter-toggle,
.show-icon-labels.interface-pinned-items .interface-pinned-items .components-button,
.show-icon-labels .edit-post-header .edit-post-header-toolbar__inserter-toggle.edit-post-header-toolbar__inserter-toggle,
.show-icon-labels .edit-post-header .interface-pinned-items .components-button,
.edit-post-header__dropdown .edit-post-header-toolbar__inserter-toggle.edit-post-header-toolbar__inserter-toggle,
.edit-post-header__dropdown .interface-pinned-items .components-button {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.show-icon-labels.interface-pinned-items .components-dropdown-menu__toggle,
.show-icon-labels .edit-post-header .components-dropdown-menu__toggle,
.edit-post-header__dropdown .components-dropdown-menu__toggle {
  margin-left: 8px;
  padding-left: 8px;
  padding-right: 8px;
}
@media (min-width: 600px) {
  .show-icon-labels.interface-pinned-items .components-dropdown-menu__toggle,
.show-icon-labels .edit-post-header .components-dropdown-menu__toggle,
.edit-post-header__dropdown .components-dropdown-menu__toggle {
    margin-left: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.show-icon-labels.interface-pinned-items .edit-post-header-toolbar__inserter-toggle.edit-post-header-toolbar__inserter-toggle::after,
.show-icon-labels .edit-post-header .edit-post-header-toolbar__inserter-toggle.edit-post-header-toolbar__inserter-toggle::after,
.edit-post-header__dropdown .edit-post-header-toolbar__inserter-toggle.edit-post-header-toolbar__inserter-toggle::after {
  content: none;
}
.show-icon-labels.interface-pinned-items .editor-post-save-draft.editor-post-save-draft::after,
.show-icon-labels .edit-post-header .editor-post-save-draft.editor-post-save-draft::after,
.edit-post-header__dropdown .editor-post-save-draft.editor-post-save-draft::after {
  content: none;
}
@media (min-width: 600px) {
  .show-icon-labels.interface-pinned-items .editor-post-save-draft.editor-post-save-draft::after,
.show-icon-labels .edit-post-header .editor-post-save-draft.editor-post-save-draft::after,
.edit-post-header__dropdown .editor-post-save-draft.editor-post-save-draft::after {
    content: attr(aria-label);
  }
}

.edit-post-header__dropdown .components-menu-item__button.components-menu-item__button,
.edit-post-header__dropdown .components-button.editor-history__undo,
.edit-post-header__dropdown .components-button.editor-history__redo,
.edit-post-header__dropdown .table-of-contents .components-button,
.edit-post-header__dropdown .components-button.block-editor-list-view {
  margin: 0;
  padding: 6px 6px 6px 40px;
  width: 14.625rem;
  text-align: left;
  justify-content: flex-start;
}

.show-icon-labels.interface-pinned-items {
  padding: 6px 12px 12px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -12px;
  margin-right: -12px;
  border-bottom: 1px solid #ccc;
  display: block;
}
.show-icon-labels.interface-pinned-items > .components-button.has-icon {
  margin: 0;
  padding: 6px 6px 6px 8px;
  width: 14.625rem;
  justify-content: flex-start;
}
.show-icon-labels.interface-pinned-items > .components-button.has-icon[aria-expanded=true] svg {
  display: block;
  max-width: 24px;
}
.show-icon-labels.interface-pinned-items > .components-button.has-icon[aria-expanded=false] {
  padding-left: 40px;
}
.show-icon-labels.interface-pinned-items > .components-button.has-icon svg {
  margin-right: 8px;
}

.edit-post-fullscreen-mode-close.components-button {
  display: none;
}
@media (min-width: 782px) {
  .edit-post-fullscreen-mode-close.components-button {
    display: flex;
    align-items: center;
    align-self: stretch;
    border: none;
    background: #1e1e1e;
    color: #fff;
    border-radius: 0;
    height: 61px;
    width: 60px;
    position: relative;
    margin-bottom: -1px;
  }
  .edit-post-fullscreen-mode-close.components-button:active {
    color: #fff;
  }
  .edit-post-fullscreen-mode-close.components-button:focus {
    box-shadow: none;
  }
  .edit-post-fullscreen-mode-close.components-button::before {
    transition: box-shadow 0.1s ease;
    content: "";
    display: block;
    position: absolute;
    top: 9px;
    right: 9px;
    bottom: 9px;
    left: 9px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) #1e1e1e;
  }
}
@media (min-width: 782px) and (prefers-reduced-motion: reduce) {
  .edit-post-fullscreen-mode-close.components-button::before {
    transition-duration: 0s;
    transition-delay: 0s;
  }
}
@media (min-width: 782px) {
  .edit-post-fullscreen-mode-close.components-button:hover::before {
    box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) #757575;
  }
}
@media (min-width: 782px) {
  .edit-post-fullscreen-mode-close.components-button.has-icon:hover::before {
    box-shadow: none;
  }
}
@media (min-width: 782px) {
  .edit-post-fullscreen-mode-close.components-button:focus::before {
    box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) rgba(255, 255, 255, 0.1), inset 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color);
  }
}
.edit-post-fullscreen-mode-close.components-button .edit-post-fullscreen-mode-close_site-icon {
  width: 36px;
  height: 36px;
  border-radius: 2px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-top: -1px;
}

.edit-post-header-toolbar {
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  border: none;
}
.edit-post-header-toolbar .edit-post-header-toolbar__left > .components-button {
  display: none;
}
@media (min-width: 600px) {
  .edit-post-header-toolbar .edit-post-header-toolbar__left > .components-button {
    display: inline-flex;
  }
}
.edit-post-header-toolbar .edit-post-header-toolbar__left > .edit-post-header-toolbar__inserter-toggle {
  display: inline-flex;
}
.edit-post-header-toolbar .edit-post-header-toolbar__left > .edit-post-header-toolbar__inserter-toggle svg {
  transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
}
@media (prefers-reduced-motion: reduce) {
  .edit-post-header-toolbar .edit-post-header-toolbar__left > .edit-post-header-toolbar__inserter-toggle svg {
    transition-duration: 0s;
    transition-delay: 0s;
  }
}
.edit-post-header-toolbar .edit-post-header-toolbar__left > .edit-post-header-toolbar__inserter-toggle.is-pressed svg {
  transform: rotate(45deg);
}
.edit-post-header-toolbar .block-editor-list-view {
  display: none;
}
@media (min-width: 600px) {
  .edit-post-header-toolbar .block-editor-list-view {
    display: flex;
  }
}
.edit-post-header-toolbar .edit-post-header-toolbar__left > .components-button.has-icon,
.edit-post-header-toolbar .edit-post-header-toolbar__left > .components-dropdown > .components-button.has-icon {
  height: 36px;
  min-width: 36px;
  padding: 6px;
}
.edit-post-header-toolbar .edit-post-header-toolbar__left > .components-button.has-icon.is-pressed,
.edit-post-header-toolbar .edit-post-header-toolbar__left > .components-dropdown > .components-button.has-icon.is-pressed {
  background: #1e1e1e;
}
.edit-post-header-toolbar .edit-post-header-toolbar__left > .components-button.has-icon:focus:not(:disabled),
.edit-post-header-toolbar .edit-post-header-toolbar__left > .components-dropdown > .components-button.has-icon:focus:not(:disabled) {
  box-shadow: 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color), inset 0 0 0 1px #fff;
  outline: 1px solid transparent;
}
.edit-post-header-toolbar .edit-post-header-toolbar__left > .components-button.has-icon::before,
.edit-post-header-toolbar .edit-post-header-toolbar__left > .components-dropdown > .components-button.has-icon::before {
  display: none;
}

@media (min-width: 600px) {
  .edit-post-header.has-reduced-ui .edit-post-header-toolbar__left > * + .components-button,
.edit-post-header.has-reduced-ui .edit-post-header-toolbar__left > * + .components-dropdown > [aria-expanded=false] {
    transition: opacity 0.1s linear;
  }
}
@media (min-width: 600px) and (prefers-reduced-motion: reduce) {
  .edit-post-header.has-reduced-ui .edit-post-header-toolbar__left > * + .components-button,
.edit-post-header.has-reduced-ui .edit-post-header-toolbar__left > * + .components-dropdown > [aria-expanded=false] {
    transition-duration: 0s;
    transition-delay: 0s;
  }
}
@media (min-width: 600px) {
  .edit-post-header.has-reduced-ui:not(:hover) .edit-post-header-toolbar__left > * + .components-button, .edit-post-header.has-reduced-ui:not(:hover) .edit-post-header-toolbar__left > * + .components-dropdown > [aria-expanded=false] {
    opacity: 0;
  }
}

.edit-post-header-toolbar__left {
  display: inline-flex;
  align-items: center;
  padding-left: 8px;
}
@media (min-width: 600px) {
  .edit-post-header-toolbar__left {
    padding-left: 24px;
  }
}
@media (min-width: 1280px) {
  .edit-post-header-toolbar__left {
    padding-right: 8px;
  }
}

.edit-post-header-toolbar .edit-post-header-toolbar__left > .edit-post-header-toolbar__inserter-toggle.has-icon {
  margin-right: 8px;
  min-width: 32px;
  width: 32px;
  height: 32px;
  padding: 0;
}
.show-icon-labels .edit-post-header-toolbar .edit-post-header-toolbar__left > .edit-post-header-toolbar__inserter-toggle.has-icon {
  height: 36px;
}

.edit-post-header-toolbar .edit-post-header-toolbar__left > .edit-post-header-toolbar__inserter-toggle.has-text.has-icon {
  width: auto;
  padding: 0 8px;
}

.show-icon-labels .edit-post-header-toolbar__left > * + * {
  margin-left: 8px;
}

.edit-post-template-top-area {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  width: 100%;
  align-items: center;
}
.edit-post-template-top-area .edit-post-template-title,
.edit-post-template-top-area .edit-post-template-post-title {
  padding: 0;
  text-decoration: none;
  height: auto;
}
.edit-post-template-top-area .edit-post-template-title::before,
.edit-post-template-top-area .edit-post-template-post-title::before {
  height: 100%;
}
.edit-post-template-top-area .edit-post-template-title.has-icon svg,
.edit-post-template-top-area .edit-post-template-post-title.has-icon svg {
  order: 1;
  margin-right: 0;
}
.edit-post-template-top-area .edit-post-template-title {
  color: #1e1e1e;
}
.edit-post-template-top-area .edit-post-template-post-title {
  margin-top: 4px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.edit-post-template-top-area .edit-post-template-post-title::before {
  left: 0;
  right: 0;
}
@media (min-width: 1080px) {
  .edit-post-template-top-area .edit-post-template-post-title {
    max-width: 400px;
  }
}

.edit-post-template-top-area__popover .components-popover__content {
  min-width: 280px;
  padding: 8px;
}
.edit-post-template-top-area__popover .edit-post-template-details__description {
  color: #757575;
}

.edit-post-template-top-area__second-menu-group {
  margin-left: -16px;
  margin-right: -16px;
  padding: 16px;
  padding-bottom: 0;
  border-top: 1px solid #ddd;
}
.edit-post-template-top-area__second-menu-group .edit-post-template-top-area__delete-template-button {
  display: flex;
  justify-content: center;
}
.edit-post-template-top-area__second-menu-group .edit-post-template-top-area__delete-template-button .components-menu-item__item {
  margin-right: 0;
  min-width: 0;
}

.edit-post-keyboard-shortcut-help-modal__section {
  margin: 0 0 2rem 0;
}
.edit-post-keyboard-shortcut-help-modal__main-shortcuts .edit-post-keyboard-shortcut-help-modal__shortcut-list {
  margin-top: -25px;
}
.edit-post-keyboard-shortcut-help-modal__section-title {
  font-size: 0.9rem;
  font-weight: 600;
}
.edit-post-keyboard-shortcut-help-modal__shortcut {
  display: flex;
  align-items: baseline;
  padding: 0.6rem 0;
  border-top: 1px solid #ddd;
  margin-bottom: 0;
}
.edit-post-keyboard-shortcut-help-modal__shortcut:last-child {
  border-bottom: 1px solid #ddd;
}
.edit-post-keyboard-shortcut-help-modal__shortcut:empty {
  display: none;
}
.edit-post-keyboard-shortcut-help-modal__shortcut-term {
  font-weight: 600;
  margin: 0 0 0 1rem;
  text-align: right;
}
.edit-post-keyboard-shortcut-help-modal__shortcut-description {
  flex: 1;
  margin: 0;
  flex-basis: auto;
}
.edit-post-keyboard-shortcut-help-modal__shortcut-key-combination {
  display: block;
  background: none;
  margin: 0;
  padding: 0;
}
.edit-post-keyboard-shortcut-help-modal__shortcut-key-combination + .edit-post-keyboard-shortcut-help-modal__shortcut-key-combination {
  margin-top: 10px;
}
.edit-post-keyboard-shortcut-help-modal__shortcut-key {
  padding: 0.25rem 0.5rem;
  border-radius: 8%;
  margin: 0 0.2rem 0 0.2rem;
}
.edit-post-keyboard-shortcut-help-modal__shortcut-key:last-child {
  margin: 0 0 0 0.2rem;
}

.edit-post-layout__metaboxes {
  flex-shrink: 0;
  clear: both;
}

.edit-post-layout .components-editor-notices__snackbar {
  position: fixed;
  right: 0;
  bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;
}

.edit-post-layout .components-editor-notices__snackbar {
  /* Set left position when auto-fold is not on the body element. */
  left: 0;
}
@media (min-width: 783px) {
  .edit-post-layout .components-editor-notices__snackbar {
    left: 160px;
  }
}

.auto-fold .edit-post-layout .components-editor-notices__snackbar {
  /* Auto fold is when on smaller breakpoints, nav menu auto collapses. */
}
@media (min-width: 783px) {
  .auto-fold .edit-post-layout .components-editor-notices__snackbar {
    left: 36px;
  }
}
@media (min-width: 961px) {
  .auto-fold .edit-post-layout .components-editor-notices__snackbar {
    left: 160px;
  }
}

/* Sidebar manually collapsed. */
.folded .edit-post-layout .components-editor-notices__snackbar {
  left: 0;
}
@media (min-width: 783px) {
  .folded .edit-post-layout .components-editor-notices__snackbar {
    left: 36px;
  }
}

body.is-fullscreen-mode .edit-post-layout .components-editor-notices__snackbar {
  left: 0 !important;
}

.edit-post-layout .editor-post-publish-panel {
  position: fixed;
  z-index: 100001;
  top: 46px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
}
@media (min-width: 782px) {
  .edit-post-layout .editor-post-publish-panel {
    z-index: 99998;
    top: 32px;
    left: auto;
    width: 281px;
    border-left: 1px solid #ddd;
    transform: translateX(100%);
    animation: edit-post-post-publish-panel__slide-in-animation 0.1s forwards;
  }
}
@media (min-width: 782px) and (prefers-reduced-motion: reduce) {
  .edit-post-layout .editor-post-publish-panel {
    animation-duration: 1ms;
    animation-delay: 0s;
  }
}
@media (min-width: 782px) {
  body.is-fullscreen-mode .edit-post-layout .editor-post-publish-panel {
    top: 0;
  }
}
@media (min-width: 782px) {
  [role=region]:focus .edit-post-layout .editor-post-publish-panel {
    transform: translateX(0%);
  }
}

@keyframes edit-post-post-publish-panel__slide-in-animation {
  100% {
    transform: translateX(0%);
  }
}
.edit-post-layout .editor-post-publish-panel__header-publish-button {
  justify-content: center;
}

.edit-post-layout__toggle-publish-panel,
.edit-post-layout__toggle-sidebar-panel,
.edit-post-layout__toggle-entities-saved-states-panel {
  z-index: 100000;
  position: fixed !important;
  top: -9999em;
  bottom: auto;
  left: auto;
  right: 0;
  width: 280px;
  background-color: #fff;
  border: 1px dotted #ddd;
  height: auto !important;
  padding: 24px;
  display: flex;
  justify-content: center;
}
.interface-interface-skeleton__actions:focus .edit-post-layout__toggle-publish-panel, .interface-interface-skeleton__actions:focus-within .edit-post-layout__toggle-publish-panel,
.interface-interface-skeleton__actions:focus .edit-post-layout__toggle-sidebar-panel,
.interface-interface-skeleton__actions:focus-within .edit-post-layout__toggle-sidebar-panel,
.interface-interface-skeleton__actions:focus .edit-post-layout__toggle-entities-saved-states-panel,
.interface-interface-skeleton__actions:focus-within .edit-post-layout__toggle-entities-saved-states-panel {
  top: auto;
  bottom: 0;
}

.edit-post-block-manager__no-results {
  font-style: italic;
  padding: 24px 0;
  text-align: center;
}

.edit-post-block-manager__search {
  margin: 16px 0;
}

.edit-post-block-manager__disabled-blocks-count {
  border: 1px solid #ddd;
  border-width: 1px 0;
  box-shadow: -32px 0 0 0 #fff, 32px 0 0 0 #fff;
  padding: 8px;
  background-color: #fff;
  text-align: center;
  font-style: italic;
  position: sticky;
  top: -1px;
  z-index: 2;
}
.edit-post-block-manager__disabled-blocks-count ~ .edit-post-block-manager__results .edit-post-block-manager__category-title {
  top: 35px;
}

.edit-post-block-manager__category {
  margin: 0 0 24px 0;
}

.edit-post-block-manager__category-title {
  position: sticky;
  top: 0;
  padding: 16px 0;
  background-color: #fff;
  z-index: 1;
}
.edit-post-block-manager__category-title .components-checkbox-control__label {
  font-weight: 600;
}

.edit-post-block-manager__show-all {
  margin-right: 8px;
}

.edit-post-block-manager__checklist {
  margin-top: 0;
}

.edit-post-block-manager__category-title,
.edit-post-block-manager__checklist-item {
  border-bottom: 1px solid #ddd;
}
.edit-post-block-manager__category-title .components-base-control__field,
.edit-post-block-manager__checklist-item .components-base-control__field {
  align-items: center;
  display: flex;
  margin: 0;
}

.edit-post-block-manager__checklist-item {
  margin-bottom: 0;
  padding-left: 16px;
}
.components-modal__content .edit-post-block-manager__checklist-item.components-checkbox-control__input-container {
  margin: 0 8px;
}
.edit-post-block-manager__checklist-item .components-checkbox-control__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 8px 0;
}
.edit-post-block-manager__checklist-item .block-editor-block-icon {
  margin-right: 10px;
  fill: #1e1e1e;
}

.edit-post-block-manager__results {
  border-top: 1px solid #ddd;
}

.edit-post-block-manager__disabled-blocks-count + .edit-post-block-manager__results {
  border-top-width: 0;
}

.edit-post-meta-boxes-area {
  position: relative;
  /**
   * The wordpress default for most meta-box elements is content-box. Some
   * elements such as textarea and input are set to border-box in forms.css.
   * These elements therefore specifically set back to border-box here, while
   * other elements (such as .button) are unaffected by Gutenberg's style
   * because of their higher specificity.
   */
  /* Match width and positioning of the meta boxes. Override default styles. */
  /* Override Default meta box stylings */
}
.edit-post-meta-boxes-area__container,
.edit-post-meta-boxes-area .inside {
  box-sizing: content-box;
}
.edit-post-meta-boxes-area textarea,
.edit-post-meta-boxes-area input {
  box-sizing: border-box;
}
.edit-post-meta-boxes-area .postbox-header {
  border-top: 1px solid #ddd;
  border-bottom: 0;
}
.edit-post-meta-boxes-area #poststuff {
  margin: 0 auto;
  padding-top: 0;
  min-width: auto;
}
.edit-post-meta-boxes-area #poststuff h3.hndle,
.edit-post-meta-boxes-area #poststuff .stuffbox > h3,
.edit-post-meta-boxes-area #poststuff h2.hndle {
  /* WordPress selectors yolo */
  box-sizing: border-box;
  color: inherit;
  font-weight: 600;
  outline: none;
  padding: 0 24px;
  position: relative;
  width: 100%;
}
.edit-post-meta-boxes-area .postbox {
  border: 0;
  color: inherit;
  margin-bottom: 0;
}
.edit-post-meta-boxes-area .postbox > .inside {
  color: inherit;
  padding: 0 24px 24px;
  margin: 0;
}
.edit-post-meta-boxes-area .postbox .handlediv {
  height: 44px;
  width: 44px;
}
.edit-post-meta-boxes-area.is-loading::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: transparent;
  z-index: 1;
}
.edit-post-meta-boxes-area .components-spinner {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 5;
}
.edit-post-meta-boxes-area .is-hidden {
  display: none;
}
.edit-post-meta-boxes-area .metabox-location-side .postbox input[type=checkbox] {
  border: 1px solid #757575;
}
.edit-post-meta-boxes-area .metabox-location-side .postbox input[type=checkbox]:checked {
  background: #fff;
  border-color: #757575;
}
.edit-post-meta-boxes-area .metabox-location-side .postbox input[type=checkbox]::before {
  margin: -3px -4px;
}

.edit-post-meta-boxes-area__clear {
  clear: both;
}

.edit-post-editor__inserter-panel,
.edit-post-editor__list-view-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.edit-post-editor__list-view-panel {
  min-width: 350px;
}

.edit-post-editor__inserter-panel-header {
  padding-top: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: flex-end;
}

.edit-post-editor__inserter-panel-content,
.edit-post-editor__list-view-panel-content {
  height: calc(100% - 36px - 8px);
}

@media (min-width: 782px) {
  .edit-post-editor__inserter-panel-content {
    height: 100%;
  }
}

.edit-post-editor__list-view-panel-header {
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding-left: 16px;
  padding-right: 4px;
}

.edit-post-editor__list-view-panel-content {
  overflow-y: auto;
  padding: 8px 6px;
}

.components-panel__header.edit-post-sidebar__panel-tabs {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 16px;
  border-top: 0;
  margin-top: 0;
}
.components-panel__header.edit-post-sidebar__panel-tabs ul {
  display: flex;
}
.components-panel__header.edit-post-sidebar__panel-tabs li {
  margin: 0;
}
.components-panel__header.edit-post-sidebar__panel-tabs .components-button.has-icon {
  display: none;
  margin: 0 0 0 auto;
  padding: 0;
  min-width: 24px;
  height: 24px;
}
@media (min-width: 782px) {
  .components-panel__header.edit-post-sidebar__panel-tabs .components-button.has-icon {
    display: flex;
  }
}

.components-panel__body.is-opened.edit-post-last-revision__panel {
  padding: 0;
  height: 48px;
}

.editor-post-last-revision__title.components-button {
  padding: 16px;
}

.editor-post-author__select {
  margin: -5px 0;
  width: 100%;
}
@supports (position: sticky) {
  .editor-post-author__select {
    width: auto;
  }
}

.edit-post-post-link__link-post-name {
  font-weight: 600;
}

.edit-post-post-link__preview-label {
  font-weight: 400;
  margin: 0;
}

.edit-post-post-link__link {
  text-align: left;
  word-wrap: break-word;
  display: block;
}

/* rtl:begin:ignore */
.edit-post-post-link__preview-link-container {
  direction: ltr;
}

/* rtl:end:ignore */
.edit-post-post-schedule {
  width: 100%;
  position: relative;
  justify-content: left;
}
.edit-post-post-schedule span {
  display: block;
  width: 45%;
}

.components-button.edit-post-post-schedule__toggle {
  text-align: right;
}

.edit-post-post-schedule__dialog .components-popover__content > div {
  padding: 0;
}

.editor-post-slug__input {
  margin: -5px 0;
  padding: 2px;
}

.edit-post-post-status .edit-post-post-publish-dropdown__switch-to-draft {
  margin-top: 15px;
  width: 100%;
  text-align: center;
}

.edit-post-post-visibility {
  width: 100%;
  justify-content: left;
}
.edit-post-post-visibility span {
  display: block;
  width: 45%;
}

@media (min-width: 782px) {
  .edit-post-post-visibility__dialog .components-popover__content {
    width: 257px;
  }
}

.edit-post-post-visibility__dialog-legend {
  font-weight: 600;
}

.edit-post-post-visibility__choice {
  margin: 10px 0;
}

.edit-post-post-visibility__dialog-radio,
.edit-post-post-visibility__dialog-label {
  vertical-align: top;
}

.edit-post-post-visibility__dialog-password-input {
  width: calc(100% - 20px);
  margin-left: 20px;
}

.edit-post-post-visibility__dialog-info {
  color: #757575;
  padding-left: 20px;
  font-style: italic;
  margin: 4px 0 0;
  line-height: 1.4;
}

.components-button.edit-post-sidebar__panel-tab {
  border-radius: 0;
  height: 48px;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  padding: 3px 15px;
  margin-left: 0;
  font-weight: 500;
}
.components-button.edit-post-sidebar__panel-tab::after {
  content: attr(data-label);
  display: block;
  font-weight: 600;
  height: 0;
  overflow: hidden;
  speak: none;
  visibility: hidden;
}
.components-button.edit-post-sidebar__panel-tab.is-active {
  box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) transparent, inset 0 -4px 0 0 var(--wp-admin-theme-color);
  position: relative;
  z-index: 1;
}
.components-button.edit-post-sidebar__panel-tab.is-active::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 1px;
  right: 0;
  left: 0;
  border-bottom: 4px solid transparent;
}
.components-button.edit-post-sidebar__panel-tab:focus {
  box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color);
  position: relative;
  z-index: 1;
}
.components-button.edit-post-sidebar__panel-tab.is-active:focus {
  box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color), inset 0 -4px 0 0 var(--wp-admin-theme-color);
}

@media (min-width: 782px) {
  .edit-post-template__modal .components-base-control {
    width: 320px;
  }
}
.edit-post-template__modal .components-modal__header {
  border-bottom: none;
}
.edit-post-template__modal .components-modal__content::before {
  margin-bottom: 4px;
}

.edit-post-template__modal-actions {
  margin-top: 12px;
}

.edit-post-template-modal__tip {
  padding: 16px 24px;
  background: #f0f0f0;
  border-radius: 2px;
}
@media (min-width: 782px) {
  .edit-post-template-modal__tip {
    width: 240px;
  }
}

.edit-post-template__notice {
  margin: 0 0 8px 0;
}
.edit-post-template__notice .components-notice__content {
  margin: 0;
}

.edit-post-template__actions button:not(:last-child) {
  margin-right: 8px;
}

h2.edit-post-template-summary__title {
  line-height: 24px;
  margin: 0 0 4px;
  font-weight: 500;
}

.edit-post-text-editor {
  position: relative;
  width: 100%;
  background-color: #fff;
  flex-grow: 1;
}
.edit-post-text-editor .editor-post-title {
  max-width: none;
  line-height: 1.4;
  font-family: Menlo, Consolas, monaco, monospace;
  font-size: 2.5em;
  font-weight: normal;
  border: 1px solid #949494;
  padding: 16px;
}
@media (min-width: 600px) {
  .edit-post-text-editor .editor-post-title {
    padding: 24px;
  }
}
.edit-post-text-editor .editor-post-title:focus {
  border-color: var(--wp-admin-theme-color);
  box-shadow: 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color);
}

.edit-post-text-editor__body {
  width: 100%;
  padding: 0 12px 12px 12px;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 960px) {
  .edit-post-text-editor__body {
    padding: 16px 24px 96px 24px;
    padding: 0 24px 24px 24px;
  }
}

.edit-post-text-editor__toolbar {
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background: rgba(255, 255, 255, 0.8);
  padding: 4px 12px;
}
@media (min-width: 600px) {
  .edit-post-text-editor__toolbar {
    padding: 12px;
  }
}
@media (min-width: 960px) {
  .edit-post-text-editor__toolbar {
    padding: 12px 24px;
  }
}
.edit-post-text-editor__toolbar h2 {
  line-height: 36px;
  margin: 0 auto 0 0;
  font-size: 13px;
  color: #1e1e1e;
}
.edit-post-text-editor__toolbar .components-button svg {
  order: 1;
}

.edit-post-visual-editor {
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: #2f2f2f;
  flex: 1 0 auto;
}
.edit-post-visual-editor .components-button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  padding: 6px 12px;
}
.edit-post-visual-editor .components-button.is-tertiary, .edit-post-visual-editor .components-button.has-icon {
  padding: 6px;
}

.edit-post-visual-editor__post-title-wrapper {
  margin-top: 4rem;
  margin-bottom: var(--wp--style--block-gap);
}
.edit-post-visual-editor__post-title-wrapper .editor-post-title {
  margin-left: auto;
  margin-right: auto;
}

.edit-post-visual-editor__exit-template-mode {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #fff;
}
.edit-post-visual-editor__exit-template-mode:active:not([aria-disabled=true]), .edit-post-visual-editor__exit-template-mode:focus:not([aria-disabled=true]), .edit-post-visual-editor__exit-template-mode:hover {
  color: #f0f0f0;
}

.edit-post-visual-editor__content-area {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
}

.edit-post-welcome-guide,
.edit-template-welcome-guide {
  width: 312px;
}
.edit-post-welcome-guide__image,
.edit-template-welcome-guide__image {
  background: #00a0d2;
  margin: 0 0 16px;
}
.edit-post-welcome-guide__image > img,
.edit-template-welcome-guide__image > img {
  display: block;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.edit-post-welcome-guide__heading,
.edit-template-welcome-guide__heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 24px;
  line-height: 1.4;
  margin: 16px 0 16px 0;
  padding: 0 32px;
}
.edit-post-welcome-guide__text,
.edit-template-welcome-guide__text {
  font-size: 13px;
  line-height: 1.4;
  margin: 0 0 24px 0;
  padding: 0 32px;
}
.edit-post-welcome-guide__inserter-icon,
.edit-template-welcome-guide__inserter-icon {
  margin: 0 4px;
  vertical-align: text-top;
}

.edit-template-welcome-guide .components-button svg {
  fill: #fff;
}

/**
 * Animations
 */
@keyframes edit-post__fade-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
html.wp-toolbar {
  background: #fff;
}

body.block-editor-page {
  background: #fff;
  /* We hide legacy notices in Gutenberg Based Pages, because they were not designed in a way that scaled well.
     Plugins can use Gutenberg notices if they need to pass on information to the user when they are editing. */
}
body.block-editor-page #wpcontent {
  padding-left: 0;
}
body.block-editor-page #wpbody-content {
  padding-bottom: 0;
}
body.block-editor-page #wpbody-content > div:not(.block-editor):not(#screen-meta) {
  display: none;
}
body.block-editor-page #wpfooter {
  display: none;
}
body.block-editor-page .a11y-speak-region {
  left: -1px;
  top: -1px;
}
body.block-editor-page ul#adminmenu a.wp-has-current-submenu::after,
body.block-editor-page ul#adminmenu > li.current > a.current::after {
  border-right-color: #fff;
}
body.block-editor-page .media-frame select.attachment-filters:last-of-type {
  width: auto;
  max-width: 100%;
}

.block-editor-page #wpwrap {
  overflow-y: auto;
}
@media (min-width: 782px) {
  .block-editor-page #wpwrap {
    overflow-y: initial;
  }
}

.edit-post-header,
.edit-post-text-editor,
.edit-post-sidebar,
.editor-post-publish-panel,
.components-popover,
.components-modal__frame,
.edit-post-editor__inserter-panel {
  box-sizing: border-box;
}
.edit-post-header *,
.edit-post-header *::before,
.edit-post-header *::after,
.edit-post-text-editor *,
.edit-post-text-editor *::before,
.edit-post-text-editor *::after,
.edit-post-sidebar *,
.edit-post-sidebar *::before,
.edit-post-sidebar *::after,
.editor-post-publish-panel *,
.editor-post-publish-panel *::before,
.editor-post-publish-panel *::after,
.components-popover *,
.components-popover *::before,
.components-popover *::after,
.components-modal__frame *,
.components-modal__frame *::before,
.components-modal__frame *::after,
.edit-post-editor__inserter-panel *,
.edit-post-editor__inserter-panel *::before,
.edit-post-editor__inserter-panel *::after {
  box-sizing: inherit;
}

@media (min-width: 600px) {
  .block-editor__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: calc(100vh - 46px);
  }
}
@media (min-width: 782px) {
  .block-editor__container {
    min-height: calc(100vh - 32px);
  }
  body.is-fullscreen-mode .block-editor__container {
    min-height: 100vh;
  }
}
.block-editor__container img {
  max-width: 100%;
  height: auto;
}
.block-editor__container iframe {
  width: 100%;
}

body.admin-color-light {
  --wp-admin-theme-color: #0085ba;
  --wp-admin-theme-color--rgb: 0, 133, 186;
  --wp-admin-theme-color-darker-10: #0073a1;
  --wp-admin-theme-color-darker-10--rgb: 0, 115, 161;
  --wp-admin-theme-color-darker-20: #006187;
  --wp-admin-theme-color-darker-20--rgb: 0, 97, 135;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.admin-color-light {
    --wp-admin-border-width-focus: 1.5px;
  }
}

body.admin-color-modern {
  --wp-admin-theme-color: #3858e9;
  --wp-admin-theme-color--rgb: 56, 88, 233;
  --wp-admin-theme-color-darker-10: #2145e6;
  --wp-admin-theme-color-darker-10--rgb: 33, 69, 230;
  --wp-admin-theme-color-darker-20: #183ad6;
  --wp-admin-theme-color-darker-20--rgb: 24, 58, 214;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.admin-color-modern {
    --wp-admin-border-width-focus: 1.5px;
  }
}

body.admin-color-blue {
  --wp-admin-theme-color: #096484;
  --wp-admin-theme-color--rgb: 9, 100, 132;
  --wp-admin-theme-color-darker-10: #07526c;
  --wp-admin-theme-color-darker-10--rgb: 7, 82, 108;
  --wp-admin-theme-color-darker-20: #064054;
  --wp-admin-theme-color-darker-20--rgb: 6, 64, 84;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.admin-color-blue {
    --wp-admin-border-width-focus: 1.5px;
  }
}

body.admin-color-coffee {
  --wp-admin-theme-color: #46403c;
  --wp-admin-theme-color--rgb: 70, 64, 60;
  --wp-admin-theme-color-darker-10: #383330;
  --wp-admin-theme-color-darker-10--rgb: 56, 51, 48;
  --wp-admin-theme-color-darker-20: #2b2724;
  --wp-admin-theme-color-darker-20--rgb: 43, 39, 36;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.admin-color-coffee {
    --wp-admin-border-width-focus: 1.5px;
  }
}

body.admin-color-ectoplasm {
  --wp-admin-theme-color: #523f6d;
  --wp-admin-theme-color--rgb: 82, 63, 109;
  --wp-admin-theme-color-darker-10: #46365d;
  --wp-admin-theme-color-darker-10--rgb: 70, 54, 93;
  --wp-admin-theme-color-darker-20: #3a2c4d;
  --wp-admin-theme-color-darker-20--rgb: 58, 44, 77;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.admin-color-ectoplasm {
    --wp-admin-border-width-focus: 1.5px;
  }
}

body.admin-color-midnight {
  --wp-admin-theme-color: #e14d43;
  --wp-admin-theme-color--rgb: 225, 77, 67;
  --wp-admin-theme-color-darker-10: #dd382d;
  --wp-admin-theme-color-darker-10--rgb: 221, 56, 45;
  --wp-admin-theme-color-darker-20: #d02c21;
  --wp-admin-theme-color-darker-20--rgb: 208, 44, 33;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.admin-color-midnight {
    --wp-admin-border-width-focus: 1.5px;
  }
}

body.admin-color-ocean {
  --wp-admin-theme-color: #627c83;
  --wp-admin-theme-color--rgb: 98, 124, 131;
  --wp-admin-theme-color-darker-10: #576e74;
  --wp-admin-theme-color-darker-10--rgb: 87, 110, 116;
  --wp-admin-theme-color-darker-20: #4c6066;
  --wp-admin-theme-color-darker-20--rgb: 76, 96, 102;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.admin-color-ocean {
    --wp-admin-border-width-focus: 1.5px;
  }
}

body.admin-color-sunrise {
  --wp-admin-theme-color: #dd823b;
  --wp-admin-theme-color--rgb: 221, 130, 59;
  --wp-admin-theme-color-darker-10: #d97426;
  --wp-admin-theme-color-darker-10--rgb: 217, 116, 38;
  --wp-admin-theme-color-darker-20: #c36922;
  --wp-admin-theme-color-darker-20--rgb: 195, 105, 34;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.admin-color-sunrise {
    --wp-admin-border-width-focus: 1.5px;
  }
}