@charset "UTF-8";
/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * SCSS Variables.
 *
 * Please use variables from this sheet to ensure consistency across the UI.
 * Don't add to this sheet unless you're pretty sure the value will be reused in many places.
 * For example, don't add rules to this sheet that affect block visuals. It's purely for UI.
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block & Editor UI.
 */
/**
 * Block paddings.
 */
/**
 * React Native specific.
 * These variables do not appear to be used anywhere else.
 */
/**
*  Converts a hex value into the rgb equivalent.
*
* @param {string} hex - the hexadecimal value to convert
* @return {string} comma separated rgb values
*/
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Focus styles.
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
:root {
  --wp-admin-theme-color: #007cba;
  --wp-admin-theme-color--rgb: 0, 124, 186;
  --wp-admin-theme-color-darker-10: #006ba1;
  --wp-admin-theme-color-darker-10--rgb: 0, 107, 161;
  --wp-admin-theme-color-darker-20: #005a87;
  --wp-admin-theme-color-darker-20--rgb: 0, 90, 135;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  :root {
    --wp-admin-border-width-focus: 1.5px;
  }
}

.editor-autocompleters__user .editor-autocompleters__no-avatar::before {
  /* stylelint-disable */
  font: normal 20px/1 dashicons;
  /* stylelint-enable */
  content: "";
  margin-right: 5px;
  vertical-align: middle;
}
.editor-autocompleters__user .editor-autocompleters__user-avatar {
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: none;
  width: 24px;
  height: 24px;
}
.editor-autocompleters__user .editor-autocompleters__user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  flex-shrink: 0;
  flex-grow: 1;
}
.editor-autocompleters__user .editor-autocompleters__user-slug {
  margin-left: 8px;
  color: #757575;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: none;
  max-width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
}
.editor-autocompleters__user:hover .editor-autocompleters__user-slug {
  color: var(--wp-admin-theme-color);
}

.document-outline {
  margin: 20px 0;
}
.document-outline ul {
  margin: 0;
  padding: 0;
}

.document-outline__item {
  display: flex;
  margin: 4px 0;
}
.document-outline__item a {
  text-decoration: none;
}
.document-outline__item .document-outline__emdash::before {
  color: #ddd;
  margin-right: 4px;
}
.document-outline__item.is-h2 .document-outline__emdash::before {
  content: "—";
}
.document-outline__item.is-h3 .document-outline__emdash::before {
  content: "——";
}
.document-outline__item.is-h4 .document-outline__emdash::before {
  content: "———";
}
.document-outline__item.is-h5 .document-outline__emdash::before {
  content: "————";
}
.document-outline__item.is-h6 .document-outline__emdash::before {
  content: "—————";
}

.document-outline__button {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: flex-start;
  margin: 0 0 0 -1px;
  padding: 2px 5px 2px 1px;
  color: #1e1e1e;
  text-align: left;
  border-radius: 2px;
}
.document-outline__button:disabled {
  cursor: default;
}
.document-outline__button:focus {
  box-shadow: 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color);
  outline: 2px solid transparent;
}

.document-outline__level {
  background: #ddd;
  color: #1e1e1e;
  border-radius: 3px;
  font-size: 13px;
  padding: 1px 6px;
  margin-right: 4px;
}
.is-invalid .document-outline__level {
  background: #f0b849;
}

.document-outline__item-content {
  padding: 1px 0;
}

.components-editor-notices__dismissible,
.components-editor-notices__pinned {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  color: #1e1e1e;
}
.components-editor-notices__dismissible .components-notice,
.components-editor-notices__pinned .components-notice {
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 12px;
  min-height: 60px;
}
.components-editor-notices__dismissible .components-notice .components-notice__dismiss,
.components-editor-notices__pinned .components-notice .components-notice__dismiss {
  margin-top: 12px;
}

.entities-saved-states__panel {
  box-sizing: border-box;
  background: #fff;
  position: fixed;
  z-index: 100001;
  top: 46px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  box-sizing: border-box;
}
.entities-saved-states__panel *,
.entities-saved-states__panel *::before,
.entities-saved-states__panel *::after {
  box-sizing: inherit;
}
.entities-saved-states__panel .entities-saved-states__find-entity {
  display: none;
}
.entities-saved-states__panel .entities-saved-states__find-entity-small {
  display: block;
}
@media (min-width: 782px) {
  .entities-saved-states__panel {
    top: 32px;
    left: auto;
    width: 280px;
    border-left: 1px solid #ddd;
  }
  body.is-fullscreen-mode .entities-saved-states__panel {
    top: 0;
  }
  .entities-saved-states__panel .entities-saved-states__find-entity {
    display: block;
  }
  .entities-saved-states__panel .entities-saved-states__find-entity-small {
    display: none;
  }
}
.entities-saved-states__panel .entities-saved-states__panel-header {
  background: #fff;
  padding-left: 8px;
  padding-right: 8px;
  height: 61px;
  border-bottom: 1px solid #ddd;
}
.entities-saved-states__panel .entities-saved-states__text-prompt {
  padding: 16px;
  padding-bottom: 4px;
}

.editor-error-boundary {
  margin: auto;
  max-width: 780px;
  padding: 20px;
  margin-top: 60px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
}

.editor-page-attributes__order {
  width: 100%;
}
.editor-page-attributes__order .components-base-control__field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editor-page-attributes__order input {
  width: 66px;
}

.editor-post-excerpt__textarea {
  width: 100%;
  margin-bottom: 10px;
}

.editor-post-featured-image {
  padding: 0;
}
.editor-post-featured-image__container {
  margin-bottom: 1em;
  position: relative;
}
.editor-post-featured-image .components-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -9px;
}
.editor-post-featured-image .components-button + .components-button {
  display: block;
  margin-top: 1em;
}
.editor-post-featured-image .components-responsive-wrapper__content {
  max-width: 100%;
  width: auto;
}

.editor-post-featured-image__toggle,
.editor-post-featured-image__preview {
  display: block;
  width: 100%;
  padding: 0;
  transition: all 0.1s ease-out;
  box-shadow: 0 0 0 0 var(--wp-admin-theme-color);
}
@media (prefers-reduced-motion: reduce) {
  .editor-post-featured-image__toggle,
.editor-post-featured-image__preview {
    transition-duration: 0s;
    transition-delay: 0s;
  }
}

.editor-post-featured-image__preview {
  height: auto;
}

.editor-post-featured-image__preview:not(:disabled):not([aria-disabled=true]):focus {
  box-shadow: 0 0 0 4px var(--wp-admin-theme-color);
}

.editor-post-featured-image__toggle {
  border-radius: 2px;
  background-color: #f0f0f0;
  min-height: 90px;
  line-height: 20px;
  padding: 8px 0;
  text-align: center;
}
.editor-post-featured-image__toggle:hover {
  background: #ddd;
  color: #1e1e1e;
}

.editor-post-format {
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.editor-post-format__content {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.editor-post-format__suggestion {
  padding: 6px;
  text-align: right;
  font-size: 13px;
}

.editor-post-last-revision__title {
  width: 100%;
  font-weight: 600;
}
.editor-post-last-revision__title .dashicon {
  margin-right: 5px;
}

.components-button.editor-post-last-revision__title {
  height: 100%;
}
.components-button.editor-post-last-revision__title:hover, .components-button.editor-post-last-revision__title:active {
  background: #f0f0f0;
}
.components-button.editor-post-last-revision__title:focus {
  box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color);
  border-radius: 0;
}

@media (min-width: 600px) {
  .editor-post-locked-modal {
    max-width: 480px;
  }
}
.editor-post-locked-modal .components-modal__content {
  display: flex;
}

.editor-post-locked-modal__buttons {
  margin-top: 24px;
}

.editor-post-locked-modal__avatar {
  border-radius: 2px;
  margin-top: 16px;
  margin-right: 24px;
}

.editor-post-publish-button__button.has-changes-dot::before {
  background: currentcolor;
  border-radius: 4px;
  content: "";
  height: 8px;
  margin: auto 5px auto -3px;
  width: 8px;
}

.editor-post-publish-panel {
  background: #fff;
}

.editor-post-publish-panel__content {
  min-height: calc(100% - 144px);
}
.editor-post-publish-panel__content .components-spinner {
  display: block;
  margin: 100px auto 0;
}

.editor-post-publish-panel__header {
  background: #fff;
  padding-left: 16px;
  padding-right: 16px;
  height: 61px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  align-content: space-between;
}
.editor-post-publish-panel__header .components-button {
  width: 100%;
  justify-content: center;
}
.editor-post-publish-panel__header .has-icon {
  margin-left: auto;
  width: auto;
}

.components-site-card {
  display: flex;
  align-items: center;
  margin: 16px 0;
}

.components-site-icon {
  border: none;
  border-radius: 2px;
  margin-right: 12px;
  height: 36px;
  width: 36px;
}

.components-site-name {
  display: block;
  font-size: 14px;
}

.components-site-home {
  display: block;
  color: #757575;
  font-size: 12px;
}

.editor-post-publish-panel__header-publish-button,
.editor-post-publish-panel__header-cancel-button {
  flex-grow: 1;
}
@media (min-width: 480px) {
  .editor-post-publish-panel__header-publish-button,
.editor-post-publish-panel__header-cancel-button {
    max-width: 160px;
  }
}

.editor-post-publish-panel__header-publish-button {
  padding-right: 4px;
}

.editor-post-publish-panel__header-cancel-button {
  padding-left: 4px;
}

.editor-post-publish-panel__header-published {
  flex-grow: 1;
}

.editor-post-publish-panel__footer {
  padding: 16px;
}

.components-button.editor-post-publish-panel__toggle.is-primary {
  display: inline-flex;
  align-items: center;
}
.components-button.editor-post-publish-panel__toggle.is-primary.is-busy .dashicon {
  display: none;
}
.components-button.editor-post-publish-panel__toggle.is-primary .dashicon {
  margin-right: -4px;
}

.editor-post-publish-panel__link {
  font-weight: 400;
  padding-left: 4px;
}

.editor-post-publish-panel__prepublish {
  padding: 16px;
}
.editor-post-publish-panel__prepublish strong {
  color: #1e1e1e;
}
.editor-post-publish-panel__prepublish .components-panel__body {
  background: #fff;
  margin-left: -16px;
  margin-right: -16px;
}
.editor-post-publish-panel__prepublish .editor-post-visibility__dialog-legend {
  display: none;
}

.post-publish-panel__postpublish .components-panel__body {
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
}

.post-publish-panel__postpublish-buttons {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  margin: -5px;
}
.post-publish-panel__postpublish-buttons > * {
  flex-grow: 1;
  margin: 5px;
}
.post-publish-panel__postpublish-buttons .components-button {
  height: auto;
  justify-content: center;
  padding: 3px 10px 4px;
  flex: 1;
  line-height: 1.6;
  text-align: center;
  white-space: normal;
}
.post-publish-panel__postpublish-buttons .components-clipboard-button {
  width: 100%;
}

.post-publish-panel__postpublish-post-address-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}
.post-publish-panel__postpublish-post-address-container .components-base-control__field {
  margin-bottom: 0;
}
.post-publish-panel__postpublish-post-address-container .post-publish-panel__postpublish-post-address {
  flex: 1;
}
.post-publish-panel__postpublish-post-address-container input[readonly] {
  padding: 10px;
  background: #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-publish-panel__postpublish-post-address__copy-button-wrap {
  flex-shrink: 0;
  margin-left: 8px;
}
.post-publish-panel__postpublish-post-address__copy-button-wrap .components-button {
  height: 38px;
}

.post-publish-panel__postpublish-header {
  font-weight: 500;
}

.post-publish-panel__postpublish-subheader {
  margin: 0 0 8px;
}

.post-publish-panel__tip {
  color: #f0b849;
}

@media screen and (max-width: 782px) {
  .post-publish-panel__postpublish-post-address__button-wrap .components-button {
    height: 40px;
  }
}
.editor-post-saved-state {
  display: flex;
  align-items: center;
  width: 28px;
  padding: 12px 4px;
  color: #757575;
  overflow: hidden;
  white-space: nowrap;
}
.editor-post-saved-state.is-saving[aria-disabled=true], .editor-post-saved-state.is-saving[aria-disabled=true]:hover, .editor-post-saved-state.is-saved[aria-disabled=true], .editor-post-saved-state.is-saved[aria-disabled=true]:hover {
  background: transparent;
  color: #757575;
}
.editor-post-saved-state svg {
  display: inline-block;
  flex: 0 0 auto;
  fill: currentColor;
  margin-right: 8px;
}
@media (min-width: 600px) {
  .editor-post-saved-state {
    width: auto;
    padding: 8px 12px;
    text-indent: inherit;
  }
  .editor-post-saved-state svg {
    margin-right: 0;
  }
}

.editor-post-taxonomies__hierarchical-terms-list {
  max-height: 14em;
  overflow: auto;
  margin-left: -6px;
  padding-left: 6px;
  margin-top: -6px;
  padding-top: 6px;
}

.editor-post-taxonomies__hierarchical-terms-choice {
  margin-bottom: 8px;
}

.editor-post-taxonomies__hierarchical-terms-subchoices {
  margin-top: 8px;
  margin-left: 16px;
}

.components-button.editor-post-taxonomies__hierarchical-terms-submit,
.components-button.editor-post-taxonomies__hierarchical-terms-add {
  margin-top: 12px;
}

.editor-post-taxonomies__hierarchical-terms-label {
  display: inline-block;
  margin-top: 12px;
}

.editor-post-taxonomies__hierarchical-terms-input {
  margin-top: 8px;
}

.editor-post-taxonomies__hierarchical-terms-filter {
  margin-bottom: 8px;
  width: 100%;
}

.editor-post-taxonomies__flat-term-most-used .editor-post-taxonomies__flat-term-most-used-label {
  font-weight: 400;
  margin-bottom: 12px;
}

.editor-post-taxonomies__flat-term-most-used-list {
  margin: 0;
}
.editor-post-taxonomies__flat-term-most-used-list li {
  display: inline-block;
  margin-right: 8px;
}
.editor-post-taxonomies__flat-term-most-used-list .components-button {
  font-size: 12px;
}

.edit-post-text-editor__body textarea.editor-post-text-editor {
  border: 1px solid #949494;
  border-radius: 0;
  display: block;
  margin: 0;
  width: 100%;
  box-shadow: none;
  resize: none;
  overflow: hidden;
  font-family: Menlo, Consolas, monaco, monospace;
  line-height: 2.4;
  min-height: 200px;
  transition: border 0.1s ease-out, box-shadow 0.1s linear;
  padding: 16px;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px !important;
}
@media (prefers-reduced-motion: reduce) {
  .edit-post-text-editor__body textarea.editor-post-text-editor {
    transition-duration: 0s;
    transition-delay: 0s;
  }
}
@media (min-width: 600px) {
  .edit-post-text-editor__body textarea.editor-post-text-editor {
    padding: 24px;
  }
}
@media (min-width: 600px) {
  .edit-post-text-editor__body textarea.editor-post-text-editor {
    font-size: 15px !important;
  }
}
.edit-post-text-editor__body textarea.editor-post-text-editor:focus {
  border-color: var(--wp-admin-theme-color);
  box-shadow: 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color);
  position: relative;
}
.edit-post-text-editor__body textarea.editor-post-text-editor::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.62);
}
.edit-post-text-editor__body textarea.editor-post-text-editor::-moz-placeholder {
  color: rgba(30, 30, 30, 0.62);
  opacity: 1;
}
.edit-post-text-editor__body textarea.editor-post-text-editor:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.62);
}

.edit-post-post-visibility__dialog,
.editor-post-visibility__dialog-fieldset {
  padding: 4px;
  padding-top: 0;
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-legend,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-legend {
  font-weight: 600;
  margin-bottom: 1em;
  margin-top: 0.5em;
  padding: 0;
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio],
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding: 6px 8px;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.1s linear;
  border-radius: 2px;
  border: 1px solid #757575;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px;
  /* Override core line-height. To be reviewed. */
  line-height: normal;
  border: 1px solid #1e1e1e;
  margin-right: 12px;
  transition: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-top: 2px;
}
@media (prefers-reduced-motion: reduce) {
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio],
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio] {
    transition-duration: 0s;
    transition-delay: 0s;
  }
}
@media (min-width: 600px) {
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio],
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio] {
    font-size: 13px;
    /* Override core line-height. To be reviewed. */
    line-height: normal;
  }
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio]:focus,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio]:focus {
  border-color: var(--wp-admin-theme-color);
  box-shadow: 0 0 0 1px var(--wp-admin-theme-color);
  outline: 2px solid transparent;
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio]::-webkit-input-placeholder,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio]::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.62);
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio]::-moz-placeholder,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio]::-moz-placeholder {
  opacity: 1;
  color: rgba(30, 30, 30, 0.62);
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio]:-ms-input-placeholder,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio]:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.62);
}
@media (min-width: 600px) {
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio],
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio] {
    height: 20px;
    width: 20px;
  }
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio]:checked::before,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio]:checked::before {
  box-sizing: inherit;
  width: 8px;
  height: 8px;
  transform: translate(7px, 7px);
  margin: 0;
  background-color: #fff;
  border: 4px solid #fff;
}
@media (min-width: 600px) {
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio]:checked::before,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio]:checked::before {
    transform: translate(5px, 5px);
  }
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio]:focus,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio]:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--wp-admin-theme-color);
  outline: 2px solid transparent;
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio[type=radio]:checked,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio[type=radio]:checked {
  background: var(--wp-admin-theme-color);
  border-color: var(--wp-admin-theme-color);
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-label,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-label {
  font-weight: 600;
}
.edit-post-post-visibility__dialog .editor-post-visibility__dialog-info,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-info {
  margin-top: 0;
  margin-left: 32px;
}
.edit-post-post-visibility__dialog .editor-post-visibility__choice:last-child .editor-post-visibility__dialog-info,
.editor-post-visibility__dialog-fieldset .editor-post-visibility__choice:last-child .editor-post-visibility__dialog-info {
  margin-bottom: 0;
}

.editor-post-visibility__dialog-password .editor-post-visibility__dialog-password-input[type=text] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding: 6px 8px;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.1s linear;
  border-radius: 2px;
  border: 1px solid #757575;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px;
  /* Override core line-height. To be reviewed. */
  line-height: normal;
  margin-left: 36px;
  margin-top: 8px;
}
@media (prefers-reduced-motion: reduce) {
  .editor-post-visibility__dialog-password .editor-post-visibility__dialog-password-input[type=text] {
    transition-duration: 0s;
    transition-delay: 0s;
  }
}
@media (min-width: 600px) {
  .editor-post-visibility__dialog-password .editor-post-visibility__dialog-password-input[type=text] {
    font-size: 13px;
    /* Override core line-height. To be reviewed. */
    line-height: normal;
  }
}
.editor-post-visibility__dialog-password .editor-post-visibility__dialog-password-input[type=text]:focus {
  border-color: var(--wp-admin-theme-color);
  box-shadow: 0 0 0 1px var(--wp-admin-theme-color);
  outline: 2px solid transparent;
}
.editor-post-visibility__dialog-password .editor-post-visibility__dialog-password-input[type=text]::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.62);
}
.editor-post-visibility__dialog-password .editor-post-visibility__dialog-password-input[type=text]::-moz-placeholder {
  opacity: 1;
  color: rgba(30, 30, 30, 0.62);
}
.editor-post-visibility__dialog-password .editor-post-visibility__dialog-password-input[type=text]:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.62);
}

.editor-post-title {
  position: relative;
}
.editor-post-title.is-focus-mode {
  opacity: 0.5;
  transition: opacity 0.1s linear;
}
@media (prefers-reduced-motion: reduce) {
  .editor-post-title.is-focus-mode {
    transition-duration: 0s;
    transition-delay: 0s;
  }
}
.editor-post-title.is-focus-mode:focus {
  opacity: 1;
}

.editor-post-trash.components-button {
  margin-top: 4px;
}

.table-of-contents__popover.components-popover .components-popover__content {
  min-width: 380px;
}

.components-popover.table-of-contents__popover {
  z-index: 99998;
}

.table-of-contents__popover .components-popover__content > div {
  padding: 16px;
}
@media (min-width: 600px) {
  .table-of-contents__popover .components-popover__content {
    max-height: calc(100vh - 120px);
    overflow-y: auto;
  }
}
.table-of-contents__popover hr {
  margin: 10px -16px 0;
}

.table-of-contents__wrapper:focus::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color);
  pointer-events: none;
}

.table-of-contents__counts {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-top: -8px;
}

.table-of-contents__count {
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: #1e1e1e;
  padding-right: 8px;
  margin-bottom: 0;
  margin-top: 8px;
}
.table-of-contents__count:nth-child(4n) {
  padding-right: 0;
}

.table-of-contents__number,
.table-of-contents__popover .word-count {
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  color: #1e1e1e;
}

.table-of-contents__title {
  display: block;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
}

.editor-template-validation-notice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editor-template-validation-notice .components-button {
  margin-left: 5px;
}