/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * SCSS Variables.
 *
 * Please use variables from this sheet to ensure consistency across the UI.
 * Don't add to this sheet unless you're pretty sure the value will be reused in many places.
 * For example, don't add rules to this sheet that affect block visuals. It's purely for UI.
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block & Editor UI.
 */
/**
 * Block paddings.
 */
/**
 * React Native specific.
 * These variables do not appear to be used anywhere else.
 */
/**
*  Converts a hex value into the rgb equivalent.
*
* @param {string} hex - the hexadecimal value to convert
* @return {string} comma separated rgb values
*/
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Focus styles.
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
:root {
  --wp-admin-theme-color: #007cba;
  --wp-admin-theme-color--rgb: 0, 124, 186;
  --wp-admin-theme-color-darker-10: #006ba1;
  --wp-admin-theme-color-darker-10--rgb: 0, 107, 161;
  --wp-admin-theme-color-darker-20: #005a87;
  --wp-admin-theme-color-darker-20--rgb: 0, 90, 135;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  :root {
    --wp-admin-border-width-focus: 1.5px;
  }
}

.nux-dot-tip::before, .nux-dot-tip::after {
  border-radius: 100%;
  content: " ";
  pointer-events: none;
  position: absolute;
}
.nux-dot-tip::before {
  animation: nux-pulse 1.6s infinite cubic-bezier(0.17, 0.67, 0.92, 0.62);
  background: rgba(0, 115, 156, 0.9);
  opacity: 0.9;
  height: 24px;
  left: -12px;
  top: -12px;
  transform: scale(0.3333333333);
  width: 24px;
}
.nux-dot-tip::after {
  background: #00739c;
  height: 8px;
  left: -4px;
  top: -4px;
  width: 8px;
}
@keyframes nux-pulse {
  100% {
    background: rgba(0, 115, 156, 0);
    transform: scale(1);
  }
}
.nux-dot-tip .components-popover__content {
  width: 350px;
}
.nux-dot-tip .components-popover__content > div {
  padding: 20px 18px;
}
@media (min-width: 600px) {
  .nux-dot-tip .components-popover__content {
    width: 450px;
  }
}
.nux-dot-tip .components-popover__content .nux-dot-tip__disable {
  position: absolute;
  right: 0;
  top: 0;
}
.nux-dot-tip[data-y-axis=top] {
  margin-top: -4px;
}
.nux-dot-tip[data-y-axis=bottom] {
  margin-top: 4px;
}
.nux-dot-tip[data-y-axis=middle][data-y-axis=left] {
  margin-left: -4px;
}
.nux-dot-tip[data-y-axis=middle][data-y-axis=right] {
  margin-left: 4px;
}
.nux-dot-tip[data-y-axis=top] .components-popover__content {
  margin-bottom: 20px;
}
.nux-dot-tip[data-y-axis=bottom] .components-popover__content {
  margin-top: 20px;
}
.nux-dot-tip[data-y-axis=middle][data-y-axis=left] .components-popover__content {
  margin-right: 20px;
}
.nux-dot-tip[data-y-axis=middle][data-y-axis=right] .components-popover__content {
  margin-left: 20px;
}
.nux-dot-tip[data-y-axis=left], .nux-dot-tip[data-y-axis=center], .nux-dot-tip[data-y-axis=right] {
  z-index: 1000001;
}
@media (max-width: 600px) {
  .nux-dot-tip[data-y-axis=left] .components-popover__content, .nux-dot-tip[data-y-axis=center] .components-popover__content, .nux-dot-tip[data-y-axis=right] .components-popover__content {
    align-self: end;
    left: 5px;
    margin: 20px 0 0 0;
    max-width: none !important;
    position: fixed;
    right: 5px;
    width: auto;
  }
}
.nux-dot-tip.components-popover:not([data-y-axis=middle])[data-y-axis=right] .components-popover__content {
  /*!rtl:ignore*/
  margin-left: 0;
}
.nux-dot-tip.components-popover:not([data-y-axis=middle])[data-y-axis=left] .components-popover__content {
  /*!rtl:ignore*/
  margin-right: 0;
}
.nux-dot-tip.components-popover.interface-more-menu-dropdown__content:not([data-y-axis=middle])[data-y-axis=right] .components-popover__content {
  /*!rtl:ignore*/
  margin-left: -12px;
}
.nux-dot-tip.components-popover.interface-more-menu-dropdown__content:not([data-y-axis=middle])[data-y-axis=left] .components-popover__content {
  /*!rtl:ignore*/
  margin-right: -12px;
}