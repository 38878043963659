/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * SCSS Variables.
 *
 * Please use variables from this sheet to ensure consistency across the UI.
 * Don't add to this sheet unless you're pretty sure the value will be reused in many places.
 * For example, don't add rules to this sheet that affect block visuals. It's purely for UI.
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block & Editor UI.
 */
/**
 * Block paddings.
 */
/**
 * React Native specific.
 * These variables do not appear to be used anywhere else.
 */
/**
*  Converts a hex value into the rgb equivalent.
*
* @param {string} hex - the hexadecimal value to convert
* @return {string} comma separated rgb values
*/
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Focus styles.
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.wp-block-audio figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-audio figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-code {
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Menlo, Consolas, monaco, monospace;
  padding: 0.8em 1em;
}

.wp-block-embed figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-embed figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.blocks-gallery-caption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .blocks-gallery-caption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-image figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-image figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-pullquote {
  border-top: 4px solid currentColor;
  border-bottom: 4px solid currentColor;
  margin-bottom: 1.75em;
  color: currentColor;
}
.wp-block-pullquote cite,
.wp-block-pullquote footer, .wp-block-pullquote__citation {
  color: currentColor;
  text-transform: uppercase;
  font-size: 0.8125em;
  font-style: normal;
}

.wp-block-quote {
  border-left: 0.25em solid currentColor;
  margin: 0 0 1.75em 0;
  padding-left: 1em;
}
.wp-block-quote cite,
.wp-block-quote footer {
  color: currentColor;
  font-size: 0.8125em;
  position: relative;
  font-style: normal;
}
.wp-block-quote.has-text-align-right {
  border-left: none;
  border-right: 0.25em solid currentColor;
  padding-left: 0;
  padding-right: 1em;
}
.wp-block-quote.has-text-align-center {
  border: none;
  padding-left: 0;
}
.wp-block-quote.is-style-plain, .wp-block-quote.is-style-large, .wp-block-quote.is-large {
  border: none;
}

.wp-block-search .wp-block-search__label {
  font-weight: bold;
}

:where(.wp-block-group.has-background) {
  padding: 1.25em 2.375em;
}

.wp-block-separator {
  border: none;
  border-bottom: 2px solid currentColor;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.4;
}
.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  width: 100px;
}
.wp-block-separator.has-background:not(.is-style-dots) {
  border-bottom: none;
  height: 1px;
}
.wp-block-separator.has-background:not(.is-style-wide):not(.is-style-dots) {
  height: 2px;
}

.wp-block-table thead {
  border-bottom: 3px solid;
}
.wp-block-table tfoot {
  border-top: 3px solid;
}
.wp-block-table td,
.wp-block-table th {
  padding: 0.5em;
  border: 1px solid;
  word-break: normal;
}
.wp-block-table figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-table figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-video figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-video figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-template-part.has-background {
  padding: 1.25em 2.375em;
  margin-top: 0;
  margin-bottom: 0;
}