/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * SCSS Variables.
 *
 * Please use variables from this sheet to ensure consistency across the UI.
 * Don't add to this sheet unless you're pretty sure the value will be reused in many places.
 * For example, don't add rules to this sheet that affect block visuals. It's purely for UI.
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block & Editor UI.
 */
/**
 * Block paddings.
 */
/**
 * React Native specific.
 * These variables do not appear to be used anywhere else.
 */
/**
*  Converts a hex value into the rgb equivalent.
*
* @param {string} hex - the hexadecimal value to convert
* @return {string} comma separated rgb values
*/
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Focus styles.
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
:root {
  --wp-admin-theme-color: #007cba;
  --wp-admin-theme-color--rgb: 0, 124, 186;
  --wp-admin-theme-color-darker-10: #006ba1;
  --wp-admin-theme-color-darker-10--rgb: 0, 107, 161;
  --wp-admin-theme-color-darker-20: #005a87;
  --wp-admin-theme-color-darker-20--rgb: 0, 90, 135;
  --wp-admin-border-width-focus: 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  :root {
    --wp-admin-border-width-focus: 1.5px;
  }
}

.components-panel__header.interface-complementary-area-header__small {
  background: #fff;
  padding-right: 4px;
}
.components-panel__header.interface-complementary-area-header__small .interface-complementary-area-header__small-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
@media (min-width: 782px) {
  .components-panel__header.interface-complementary-area-header__small {
    display: none;
  }
}

.interface-complementary-area-header {
  background: #fff;
  padding-right: 4px;
}
.interface-complementary-area-header .components-button.has-icon {
  display: none;
  margin-left: auto;
}
.interface-complementary-area-header .components-button.has-icon ~ .components-button {
  margin-left: 0;
}
@media (min-width: 782px) {
  .interface-complementary-area-header .components-button.has-icon {
    display: flex;
  }
}

@media (min-width: 782px) {
  .components-panel__header + .interface-complementary-area-header {
    margin-top: 0;
  }
}

.interface-complementary-area {
  background: #fff;
  color: #1e1e1e;
}
@media (min-width: 600px) {
  .interface-complementary-area {
    -webkit-overflow-scrolling: touch;
  }
}
@media (min-width: 782px) {
  .interface-complementary-area {
    width: 280px;
  }
}
.interface-complementary-area .components-panel {
  border: none;
  position: relative;
  z-index: 0;
}
.interface-complementary-area .components-panel__header {
  position: sticky;
  top: 0;
  z-index: 1;
}
.interface-complementary-area .components-panel__header.edit-post-sidebar__panel-tabs {
  top: 48px;
}
@media (min-width: 782px) {
  .interface-complementary-area .components-panel__header.edit-post-sidebar__panel-tabs {
    top: 0;
  }
}
.interface-complementary-area p {
  margin-top: 0;
}
.interface-complementary-area h2 {
  font-size: 13px;
  color: #1e1e1e;
  margin-bottom: 1.5em;
}
.interface-complementary-area h3 {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 1.5em;
}
.interface-complementary-area hr {
  border-top: none;
  border-bottom: 1px solid #f0f0f0;
  margin: 1.5em 0;
}
.interface-complementary-area div.components-toolbar-group,
.interface-complementary-area div.components-toolbar {
  box-shadow: none;
  margin-bottom: 1.5em;
}
.interface-complementary-area div.components-toolbar-group:last-child,
.interface-complementary-area div.components-toolbar:last-child {
  margin-bottom: 0;
}
.interface-complementary-area .block-editor-skip-to-selected-block:focus {
  top: auto;
  right: 10px;
  bottom: 10px;
  left: auto;
}

@media (min-width: 782px) {
  body.js.is-fullscreen-mode {
    margin-top: -32px;
    height: calc(100% + 32px);
  }
  body.js.is-fullscreen-mode #adminmenumain,
body.js.is-fullscreen-mode #wpadminbar {
    display: none;
  }
  body.js.is-fullscreen-mode #wpcontent,
body.js.is-fullscreen-mode #wpfooter {
    margin-left: 0;
  }
}

html.interface-interface-skeleton__html-container {
  position: fixed;
  width: 100%;
}
@media (min-width: 782px) {
  html.interface-interface-skeleton__html-container {
    position: initial;
    width: initial;
  }
}

.interface-interface-skeleton {
  display: flex;
  flex-direction: row;
  height: auto;
  max-height: 100%;
  position: fixed;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (min-width: 783px) {
  .interface-interface-skeleton {
    top: 32px;
  }
  .is-fullscreen-mode .interface-interface-skeleton {
    top: 0;
  }
}

.interface-interface-skeleton__editor {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  overflow: hidden;
}

.interface-interface-skeleton {
  /* Set left position when auto-fold is not on the body element. */
  left: 0;
}
@media (min-width: 783px) {
  .interface-interface-skeleton {
    left: 160px;
  }
}

.auto-fold .interface-interface-skeleton {
  /* Auto fold is when on smaller breakpoints, nav menu auto collapses. */
}
@media (min-width: 783px) {
  .auto-fold .interface-interface-skeleton {
    left: 36px;
  }
}
@media (min-width: 961px) {
  .auto-fold .interface-interface-skeleton {
    left: 160px;
  }
}

/* Sidebar manually collapsed. */
.folded .interface-interface-skeleton {
  left: 0;
}
@media (min-width: 783px) {
  .folded .interface-interface-skeleton {
    left: 36px;
  }
}

body.is-fullscreen-mode .interface-interface-skeleton {
  left: 0 !important;
}

.interface-interface-skeleton__body {
  flex-grow: 1;
  display: flex;
  overflow: auto;
  overscroll-behavior-y: none;
}
@media (min-width: 782px) {
  .has-footer .interface-interface-skeleton__body {
    padding-bottom: 25px;
  }
}

.interface-interface-skeleton__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 91;
}

.interface-interface-skeleton__secondary-sidebar,
.interface-interface-skeleton__sidebar {
  display: block;
  flex-shrink: 0;
  position: absolute;
  z-index: 100000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  color: #1e1e1e;
}
@media (min-width: 782px) {
  .interface-interface-skeleton__secondary-sidebar,
.interface-interface-skeleton__sidebar {
    position: relative !important;
    z-index: 90;
    width: auto;
  }
}

.interface-interface-skeleton__sidebar {
  overflow: auto;
}
@media (min-width: 782px) {
  .interface-interface-skeleton__sidebar {
    border-left: 1px solid #e0e0e0;
  }
}

@media (min-width: 782px) {
  .interface-interface-skeleton__secondary-sidebar {
    border-right: 1px solid #e0e0e0;
  }
}

.interface-interface-skeleton__header {
  flex-shrink: 0;
  height: auto;
  border-bottom: 1px solid #e0e0e0;
  z-index: 30;
  color: #1e1e1e;
}

.interface-interface-skeleton__footer {
  height: auto;
  flex-shrink: 0;
  border-top: 1px solid #e0e0e0;
  color: #1e1e1e;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 90;
  display: none;
}
@media (min-width: 782px) {
  .interface-interface-skeleton__footer {
    display: flex;
  }
}
.interface-interface-skeleton__footer .block-editor-block-breadcrumb {
  z-index: 30;
  display: flex;
  background: #fff;
  height: 24px;
  align-items: center;
  font-size: 13px;
  padding: 0 18px;
}

.interface-interface-skeleton__actions {
  z-index: 100000;
  position: fixed !important;
  top: -9999em;
  bottom: auto;
  left: auto;
  right: 0;
  width: 280px;
  color: #1e1e1e;
}
.interface-interface-skeleton__actions:focus {
  top: auto;
  bottom: 0;
}

.interface-more-menu-dropdown {
  margin-left: -4px;
}
.interface-more-menu-dropdown .components-button {
  width: auto;
  padding: 0 2px;
}
@media (min-width: 600px) {
  .interface-more-menu-dropdown {
    margin-left: 0;
  }
  .interface-more-menu-dropdown .components-button {
    padding: 0 4px;
  }
}

.interface-more-menu-dropdown__content .components-popover__content {
  min-width: 280px;
}
@media (min-width: 480px) {
  .interface-more-menu-dropdown__content .components-popover__content {
    width: auto;
    max-width: 480px;
  }
}
.interface-more-menu-dropdown__content .components-popover__content .components-dropdown-menu__menu {
  padding: 0;
}

.components-popover.interface-more-menu-dropdown__content {
  z-index: 99998;
}

.interface-pinned-items {
  display: flex;
}
.interface-pinned-items .components-button:not(:first-child) {
  display: none;
}
@media (min-width: 600px) {
  .interface-pinned-items .components-button:not(:first-child) {
    display: flex;
  }
}
.interface-pinned-items .components-button {
  margin-left: 4px;
}
.interface-pinned-items .components-button svg {
  max-width: 24px;
  max-height: 24px;
}

@media (min-width: 600px) {
  .interface-preferences-modal {
    width: calc(100% - 32px);
    height: calc(100% - 120px);
  }
}
@media (min-width: 782px) {
  .interface-preferences-modal {
    width: 750px;
  }
}
@media (min-width: 960px) {
  .interface-preferences-modal {
    height: 70%;
  }
}
@media (max-width: 781px) {
  .interface-preferences-modal .components-modal__content {
    padding: 0;
  }
  .interface-preferences-modal .components-modal__content::before {
    content: none;
  }
}

.interface-preferences__tabs .components-tab-panel__tabs {
  position: absolute;
  top: 84px;
  left: 16px;
  width: 160px;
}
.interface-preferences__tabs .components-tab-panel__tabs .components-tab-panel__tabs-item {
  border-radius: 2px;
  font-weight: 400;
}
.interface-preferences__tabs .components-tab-panel__tabs .components-tab-panel__tabs-item.is-active {
  background: #f0f0f0;
  box-shadow: none;
  font-weight: 500;
}
.interface-preferences__tabs .components-tab-panel__tabs .components-tab-panel__tabs-item:focus:not(:disabled) {
  box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color);
}
.interface-preferences__tabs .components-tab-panel__tab-content {
  padding-left: 24px;
  margin-left: 160px;
}

@media (max-width: 781px) {
  .interface-preferences__provider {
    height: 100%;
  }
}
.interface-preferences-modal__section {
  margin: 0 0 2.5rem 0;
}
.interface-preferences-modal__section:last-child {
  margin: 0;
}

.interface-preferences-modal__section-title {
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 0;
}

.interface-preferences-modal__section-description {
  margin: -8px 0 8px 0;
  font-size: 12px;
  font-style: normal;
  color: #757575;
}

.interface-preferences-modal__option .components-base-control .components-base-control__field {
  align-items: center;
  display: flex;
  margin-bottom: 0;
}
.interface-preferences-modal__option .components-base-control .components-base-control__field > label {
  flex-grow: 1;
  padding: 0.6rem 0 0.6rem 10px;
}
.interface-preferences-modal__option .components-base-control__help {
  margin: -8px 0 8px 58px;
  font-size: 12px;
  font-style: normal;
  color: #757575;
}